<template>
<svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 15 15" fill="none">
    <path d="M3.49982 6.27251C3.18945 6.04341 2.9828 5.7004 2.92534 5.31893C2.86787 4.93747 2.9643 4.5488 3.1934 4.23843C3.4225 3.92806 3.76552 3.72141 4.14698 3.66394C4.52845 3.60648 4.91712 3.7029 5.22749 3.93201C5.53786 4.16111 5.74451 4.50412 5.80197 4.88559C5.85944 5.26705 5.76301 5.65572 5.53391 5.96609C5.30481 6.27646 4.96179 6.48311 4.58033 6.54058C4.19886 6.59804 3.81019 6.50162 3.49982 6.27251ZM8.53346 6.37233C8.79756 5.51009 8.78757 4.58715 8.50487 3.73083C8.22217 2.87451 7.6807 2.12702 6.95515 1.5915C6.02404 0.904188 4.85803 0.614914 3.71363 0.78731C2.56924 0.959706 1.5402 1.57965 0.85289 2.51076C0.165583 3.44188 -0.123692 4.60789 0.0487043 5.75228C0.2211 6.89668 0.841046 7.92572 1.77216 8.61302C2.49766 9.1486 3.3715 9.44584 4.2731 9.46371C5.1747 9.48159 6.05963 9.21924 6.80579 8.71284L9.3511 10.5917L7.62343 12.9322L9.96394 14.6598L11.6916 12.3193L12.8619 13.1832L14.5895 10.8427L8.53346 6.37233Z"
    :fill="fill"/>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 15
    },
    height: {
      type: [Number, String],
      default: 15
    },
    fill: {
      type: String,
      default: '#9CA6BA'
    }
  }
}
</script>
